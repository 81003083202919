import baseMixinTypes, { baseActionTypes } from "@/store/shared/base/types";
import routeTypes from "@/store/shared/route/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "vacation-plan-holidays";

export const getterTypes = {
	yearValues: "yearValues"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseActionTypes,
	...routeTypes.actionTypes,
	fetchYears: "fetchYears",
	fetchCalendar: "fetchCalendar",
	createYear: "createYear"
};

export const mutationTypes = {
	...baseMixinTypes.mutationTypes,
	...stateManipulationMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_FILTER_YEAR: "SET_FILTER_YEAR",
	RESET_FILTER: "RESET_FILTER",
	SET_IS_YEARS_LOADING: "SET_IS_YEARS_LOADING",
	SET_IS_YEAR_CREATING: "SET_IS_YEAR_CREATING",
	SET_YEARS: "SET_YEARS",
	SET_CALENDAR_DATES: "SET_CALENDAR_DATES",
	SET_IS_CALENDAR_LOADING: "SET_IS_CALENDAR_LOADING",
	SET_IS_CALENDAR_UPDATING: "SET_IS_CALENDAR_UPDATING",
};

const vacationPlanHolidaysTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default vacationPlanHolidaysTypes;
