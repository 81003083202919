export enum ExpertiseSubTypeEnum {
	// Общие вопросы
	GENERAL_ISSUES = "general_issues",
	
	// Для выдачи займа/транша
	FOR_ISSUING_LOAN_TRANCHE = "for_issuing_loan_tranche",
	
	// К подписанию ДЗ
	SIGNED_BY_DZ = "signed_by_dz"
}
