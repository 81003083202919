import urls, { urlTemplateParts } from "@/api/config/urls";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiApp from "@/api/types/app/apiApp";
import { plainToInstance } from "class-transformer";
import ApiDocumentsListResponse from "@/api/bod/types/apiDocumentsListResponse";
import ApiDocumentListItem from "@/api/bod/types/apiDocumentListItem";
import ApiGetChangeRequestsParameters from "@/api/bod/types/apiGetChangeRequestsParameters";
import ApiGetDocumentsParameters from "@/api/bod/types/apiGetDocumentsParameters";
import ApiChangeRequestsListItem from "@/api/bod/types/apiChangeRequestsListItem";
import ApiChangeRequestsListResponse from "@/api/bod/types/apiChangeRequestsListResponse";
import ApiCreateDocumentChangeRequestRequest from "@/api/bod/types/apiCreateDocumentChangeRequestRequest";
import ApiBodPublicFile from "@/api/bod/types/apiBodPublicFile";
import ApiSection from "@/api/bod/types/apiSection";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import { prepareFormData } from "@/utils/prepareFormData";
import { getFormDataHeaders } from "@/utils/getFormDataHeaders";
import ApiCreateBodFile from "@/api/bod/types/apiCreateBodFile";
import ApiDocument from "@/api/bod/types/apiDocument";
import ApiCreateDocumentRequest from "@/api/bod/types/apiCreateDocumentRequest";
import ApiChangeRequest from "@/api/bod/types/apiChangeRequest";
import ApiUpdateDocumentRequest from "@/api/bod/types/apiUpdateDocumentRequest";
import { filterApiPayload } from "@/utils/api";
import ApiCreateRequestResponse from "@/api/bod/types/apiCreateRequestResponse";
import ApiUpdateRequest from "@/api/bod/types/apiUpdateRequest";
import ApiCreateDocumentUnpublishRequestRequest from "@/api/bod/types/apiCreateDocumentUnpublishRequestRequest";
import ApiCreateRollbackDocumentRequestRequest from "@/api/bod/types/apiCreateRollbackDocumentRequestRequest";
import ApiCreateDocumentRepublishRequestRequest from "@/api/bod/types/apiCreateDocumentRepublishRequest";

export class BodController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getChangeRequests = async (parameters: ApiGetChangeRequestsParameters): Promise<ApiChangeRequestsListResponse> => {
		let data = await this.client.get<{ items: ApiChangeRequestsListItem[], totalCount: number }>(prepareUrl(urls.bod.requests.getChangeRequests,
			parameters));

		return plainToInstance(ApiChangeRequestsListResponse, data);
	};

	getRequest = async (requestId: string): Promise<ApiChangeRequest> => {
		let data = await this.client.get(urls.bod.requests.getRequest.replace(urlTemplateParts.id, requestId));
		return plainToInstance(ApiChangeRequest, data);
	};

	createChangeRequest = async (request: ApiCreateDocumentRequest): Promise<ApiCreateRequestResponse> => {
		const data = await this.client.post(urls.bod.requests.createChangeRequest, request);
		return plainToInstance(ApiCreateRequestResponse, data);
	};

	updateChangeRequest = async (documentId: string, request: ApiCreateDocumentChangeRequestRequest): Promise<ApiCreateRequestResponse> => {
		const data = await this.client.post(urls.bod.requests.updateChangeRequest.replace(urlTemplateParts.id, documentId), request);
		return plainToInstance(ApiCreateRequestResponse, data);
	};

	unpublishChangeRequest = async (documentId: string,
		request: ApiCreateDocumentUnpublishRequestRequest): Promise<ApiCreateRequestResponse> => {
		const data = await this.client.post(urls.bod.requests.unpublishChangeRequest.replace(urlTemplateParts.id, documentId), request);
		return plainToInstance(ApiCreateRequestResponse, data);
	};
	
	republishChangeRequest = async (documentId: string,
		request: ApiCreateDocumentRepublishRequestRequest): Promise<ApiCreateRequestResponse> => {
		const data = await this.client.post(urls.bod.requests.republishChangeRequest.replace(urlTemplateParts.id, documentId), request);
		return plainToInstance(ApiCreateRequestResponse, data);
	};

	rollbackChangeRequest = async (documentId: string,
		request: ApiCreateRollbackDocumentRequestRequest): Promise<ApiCreateRequestResponse> => {
		const data = await this.client.post(urls.bod.requests.rollbackChangeRequest.replace(urlTemplateParts.id, documentId), request);
		return plainToInstance(ApiCreateRequestResponse, data);
	};

	getDocuments = async (parameters: ApiGetDocumentsParameters): Promise<ApiDocumentsListResponse> => {
		let data = await this.client.get<{ items: ApiDocumentListItem[], totalCount: number }>(prepareUrl(urls.bod.documents.getDocuments,
			parameters));

		return plainToInstance(ApiDocumentsListResponse, data);
	};

	getDocument = async (documentId: string): Promise<ApiDocument> => {
		let data = await this.client.get(urls.bod.documents.getDocument.replace(urlTemplateParts.id, documentId));
		return plainToInstance(ApiDocument, data);
	};

	updateDocument = async (documentId: string, document: ApiUpdateDocumentRequest): Promise<ApiDocument> => {
		filterApiPayload(document, [undefined]);

		let data = await this.client.patch(urls.bod.documents.updateDocument.replace(urlTemplateParts.id, documentId), document);
		return plainToInstance(ApiDocument, data);
	};

	updateRequest = async (requestId: string, request: ApiUpdateRequest): Promise<ApiChangeRequest> => {
		filterApiPayload(request, [undefined]);

		let data = await this.client.patch(urls.bod.requests.updateRequest.replace(urlTemplateParts.id, requestId), request);
		return plainToInstance(ApiChangeRequest, data);
	};

	getDocumentLink = async (documentId: string): Promise<ApiBodPublicFile> => {
		let data = await this.client.get<{ items: ApiDocumentListItem[], totalCount: number }>(urls.bod.documents.getDocumentLink.replace(
			urlTemplateParts.id,
			documentId));
		return plainToInstance(ApiBodPublicFile, data);
	};

	getUsers = async (): Promise<ApiBodUser[]> => {
		const data = await this.client.get<ApiApp[]>(urls.bod.dictionaries.getUsers);
		return plainToInstance(ApiBodUser, data);
	};

	getResponsibleUsers = async (): Promise<ApiBodUser[]> => {
		const data = await this.client.get<ApiApp[]>(urls.bod.documents.getResponsibleUsers);
		return plainToInstance(ApiBodUser, data);
	};

	getInitiatorUsers = async (): Promise<ApiBodUser[]> => {
		const data = await this.client.get<ApiApp[]>(urls.bod.requests.getInitiatorUsers);
		return plainToInstance(ApiBodUser, data);
	};

	getSections = async (): Promise<ApiSection[]> => {
		const data = await this.client.get<ApiApp[]>(urls.bod.dictionaries.getSections);
		return plainToInstance(ApiSection, data);
	};

	getFinanceSchemes = async (): Promise<ApiFinanceScheme[]> => {
		const data = await this.client.get<ApiApp[]>(urls.bod.dictionaries.getFinanceSchemes);
		return plainToInstance(ApiFinanceScheme, data);
	};

	getDepartments = async (): Promise<ApiDepartment[]> => {
		const data = await this.client.get<ApiApp[]>(urls.bod.dictionaries.getDepartments);
		return plainToInstance(ApiDepartment, data);
	};

	createTemperFile = async (file: ApiCreateBodFile): Promise<ApiFileMeta> => {
		let result = await this.client.post<object>(urls.bod.file.createTemper, prepareFormData(file), getFormDataHeaders());

		return plainToInstance(ApiFileMeta, result);
	};

	getFile = async (id: string) => {
		return this.client.get<Blob>(prepareUrl(urls.bod.file.get.replace(urlTemplateParts.id, id)), { responseType: "blob" });
	};

	getPermissions = async (): Promise<string[]> => {
		return ["bod:document:update"];
	};

	acceptRequest = async (requestId: string): Promise<void> => {
		await this.client.post<object>(urls.bod.requests.accept.replace(urlTemplateParts.id, requestId), {});
	};

	declineRequest = async (requestId: string, reason: string): Promise<void> => {
		await this.client.post<object>(urls.bod.requests.decline.replace(urlTemplateParts.id, requestId), { reason });
	};

	getCurrentUser = async (): Promise<ApiBodUser> => {
		const result = await this.client.get<object>(urls.bod.user.getCurrentUser);

		return plainToInstance(ApiBodUser, result);
	};

	getDocumentPermissions = async (documentId: string): Promise<string[]> => {
		return await this.client.get(urls.bod.documents.getPermissions.replace(urlTemplateParts.id, documentId));
	};

	getRequestPermissions = async (requestId: string): Promise<string[]> => {
		return await this.client.get(urls.bod.requests.getPermissions.replace(urlTemplateParts.id, requestId));
	};
}
