import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";

export enum CalendarDateColorEnum {
	RED = "red",
	GREY = "grey",
	BLACK = "black",
}

export const CalendarDateColorMap = new Map<ApiCalendarDayTypeEnum, CalendarDateColorEnum>([
	[ApiCalendarDayTypeEnum.WorkingDay, CalendarDateColorEnum.BLACK],
	[ApiCalendarDayTypeEnum.Holiday, CalendarDateColorEnum.RED],
	[ApiCalendarDayTypeEnum.Weekend, CalendarDateColorEnum.RED],
]);
