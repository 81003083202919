import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "main-info";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	version: "version",
	refundSourceInfos: "refundSourceInfos",
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_MODE: "SET_MODE",
	SET_EDITABLE_VERSION: "SET_EDITABLE_VERSION",
	VERSION_UPDATED_EVENT: "VERSION_UPDATED_EVENT",
	SET_MAIN_DEBT: "SET_MAIN_DEBT",
	SET_INTEREST: "SET_INTEREST",
	SET_PENALTY: "SET_PENALTY",
	SET_REFUND_STATUS_TYPE_ID: "SET_REFUND_STATUS_TYPE_ID",
	SET_REFUND_WITHOUT_ACCEPTANCE: "SET_REFUND_WITHOUT_ACCEPTANCE",
	SET_REFUND_BANK_GUARANTEE: "SET_REFUND_BANK_GUARANTEE",
	SET_REFUND_PENALTY: "SET_REFUND_PENALTY",
	SET_KEY_DATES_RECALL_REASON: "SET_KEY_DATES_RECALL_REASON",
	SET_KEY_DATES_RECALL_DATE: "SET_KEY_DATES_RECALL_DATE",
	SET_KEY_DATES_REQUIREMENTS: "SET_KEY_DATES_REQUIREMENTS",
	SET_KEY_DATES_DECLARATION: "SET_KEY_DATES_DECLARATION",
	SET_KEY_DATES_SETTLEMENT: "SET_KEY_DATES_SETTLEMENT",
	SET_LEGAL_DEPARTMENT_USER_ID: "SET_LEGAL_DEPARTMENT_USER_ID",
	SET_PROJECT_MANAGER_USER_ID: "SET_PROJECT_MANAGER_USER_ID",
};

const mainInfoTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default mainInfoTypes;
