<template>
	<div :style="styleObject" class="d-flex" v-on="$listeners">
		<inline-svg :style="svgStyles" :src="processedSrc"></inline-svg>
	</div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
	props: {
		src: {
			type: String,
			required: true
		},
		height: [String, Number],
		width: {
			type: [String, Number],
			default: "fit-content"
		},
		color: String
	},
	computed: {
		svgStyles() {
			return {
				height: this.height,
				width: this.width
			};
		},
		styleObject() {
			return {
				color: this.color
			};
		},
		processedSrc() {
			return `${process.env.VUE_APP_BASE_URL}/img/icons/${this.src}.svg`;
		}
	},
	components: {
		InlineSvg
	}
};
</script>
