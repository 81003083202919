import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";

export const namespace = "troubled-borrower";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	fetchCompany: "fetchCompany",
	fetchOrganizations: "fetchOrganizations",
	fetchObligedCompanies: "fetchObligedCompanies",
	fetchVersion: "fetchVersion",
	fetchVersionHeaders: "fetchVersionHeaders",
	fetchDictionaries: "fetchDictionaries",
	createVersion: "createVersion",
	fetchIncomePredictionHistory: "fetchIncomePredictionHistory",
	fetchRefundStatusTypeHistory: "fetchRefundStatusTypeHistory",
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	SET_VERSION: "SET_VERSION",
	SET_VERSION_HEADERS: "SET_VERSION_HEADERS",
	SET_COMPANY: "SET_COMPANY",
	SET_ORGANIZATIONS: "SET_ORGANIZATIONS",
	SET_OBLIGED_COMPANIES: "SET_OBLIGED_COMPANIES",
	SET_IS_ORGANIZATIONS_LOADING: "SET_IS_ORGANIZATIONS_LOADING",
	SET_IS_OBLIGED_COMPANIES_LOADING: "SET_IS_OBLIGED_COMPANIES_LOADING",
	SET_EVENT_TYPES: "SET_EVENT_TYPES",
	SET_REQUIREMENT_TYPES: "SET_REQUIREMENT_TYPES",
	SET_ASSESSMENTS_TYPES: "SET_ASSESSMENTS_TYPES",
	SET_SECURITY_KIND_TYPES: "SET_SECURITY_KIND_TYPES",
	SET_PROJECT_MANAGERS: "SET_PROJECT_MANAGERS",
	SET_PROBLEM_DEBT_DEPARTMENTS: "SET_PROBLEM_DEBT_DEPARTMENTS",
	SET_DEBTOR_STAGE_TYPES: "SET_DEBTOR_STAGE_TYPES",
	SET_DEBTOR_STAGE_STATUS_TYPES: "SET_DEBTOR_STAGE_STATUS_TYPES",
	SET_JUDICIAL_DECISION_TYPES: "SET_JUDICIAL_DECISION_TYPES",
	SET_REFUND_STATUS_TYPES: "SET_REFUND_STATUS_TYPES",
	SET_IS_VERSION_HEADERS_LOADING: "SET_IS_VERSION_HEADERS_LOADING",
	SET_IS_VERSION_LOADING: "SET_IS_VERSION_LOADING",
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	RESET_VERSION_NAME: "RESET_VERSION_NAME",
	SET_VERSION_NAME: "SET_VERSION_NAME",
	SET_PROJECT_ROLE_TYPES: "SET_PROJECT_ROLE_TYPES",
	SET_REFUND_SOURCE_TYPES: "SET_REFUND_SOURCE_TYPES",
	SET_REFUND_SOURCE_HISTORY: "SET_REFUND_SOURCE_HISTORY",
	SET_INCOME_PREDICTION_HISTORY: "SET_INCOME_PREDICTION_HISTORY",
	SET_REFUND_STATUS_TYPE_HISTORY: "SET_REFUND_STATUS_TYPE_HISTORY",
	SET_COST_TYPES: "SET_COST_TYPES",
};

const troubledBorrowerTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default troubledBorrowerTypes;
