export enum ApiTroubledBorrowerStageStatusEnum {
	// Этап выполнен
	COMPLETED = "b840287d-3006-4a0b-b137-8a5fd6b0267d",
	
	// Этап выполнен с нарушением срока
	COMPLETED_OUT_OF_TIME = "a14868e2-7975-4cf2-9d2e-b8bff83283e6",
	
	// Этап выполняется в срок
	IN_PROGRESS = "3f7a09fa-6890-447a-ad71-3f0eebbfa403",
	
	// Начало этапа не подтверждено
	UNCONFIRMED = "15ce7064-720c-4e82-b370-343587442cb7",
	
	// Этап выполняется с нарушением срока
	IN_PROGRESS_OUT_OF_TIME = "a7ed99d2-1e6a-4cae-a3ed-57e90938c0da",
	
	// Этап не выполнен
	NOT_COMPLETED = "7fb9cea2-100b-431e-b303-436d4cfaabfc",
	
	// Этап не применим
	NOT_APPLICABLE = "f3ccef7b-7b15-4a4f-aa50-79be3fe7fd2a",
	
	// Этап прекращен по решению суда
	COURT_TERMINATED = "9de03d2a-e35d-4d8e-9d3b-e7b811848b12",
}
