<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$t('dialogs.uploadTrancheDocument.title', { date: formatDate(currentDialogTranche.plannedDate, dateFormat) })"
				max-width="656"
				persistent
				@update:value="onDialogValueUpdated">
		<template #content="{ onIntersect }">
			<v-container v-mutate="onIntersect">
				<v-row>
					<v-col class="px-0">
						<bar-dropzone max-size="25"
									  @file:add="handleUploaded($event, STORAGE_TRANCHE_DOCUMENT_NAMESPACE)"
									  @file:delete="documentFile = null"
									  :formats="['pdf']"
									  :file="documentFile"
									  :is-uploading="isDocumentUploading"
									  @format:is-valid="isValidFileFormat = $event"
									  :type="STORAGE_TRANCHE_DOCUMENT_NAMESPACE">
						</bar-dropzone>
					</v-col>
				</v-row>
			</v-container>
		</template>
		
		<template #footer>
			<frp-btn @click="handleClose"
					 :disabled="isDocumentUploading"
					 elevation="0" color="primary" outlined>
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" dark color="blue"
					 :disabled="!documentFile"
					 :loading="isDocumentUploading"
					 @click="upload">
				{{ $t(`buttons.upload`) }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { LoanStorageController } from "@/api/loanStorage";
import ApiFile from "@/api/types/storage/apiFile";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDigitalSignatureDialog from "@/components/digitalSignature/FrpDigitalSignatureDialog.vue";
import BarDropzone from "@/components/dropzone/BarDropzone.vue";
import { RouteNames } from "@/router/tranches/routes";
import AbortService from "@/services/abortService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import storageMapper from "@/store/shared/storage/mapper";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import { STORAGE_TRANCHE_DOCUMENT_NAMESPACE } from "@/constants/storage";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const loanStorageController = new LoanStorageController(abortService);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			STORAGE_TRANCHE_DOCUMENT_NAMESPACE: STORAGE_TRANCHE_DOCUMENT_NAMESPACE,
			namespace,
			formatDate,
			dateFormat,
			isSignatureDialogOpened: false,
			documentFile: null,
			isValidFileFormat: false
		};
	},
	computed: {
		...mapGetters({
			closestConfirmedTranche: getterTypes.closestConfirmedTranche
		}),
		...mapState({
			isDocumentUploading: state => state.isDocumentUploading
		}),
		...mapGetters({
			currentDialogTranche: getterTypes.currentDialogTranche
		})
	},
	methods: {
		...mapActions({
			addTrancheDocument: actionTypes.addTrancheDocument
		}),
		...mapMutations({
			setCurrentDialogTrancheNumber: mutationTypes.SET_CURRENT_DIALOG_TRANCHE_NUMBER
		}),
		onDialogValueUpdated(value) {
			if(!value)
				this.handleClose();
		},
		handleClose() {
			this.documentFile = null;
			this.setCurrentDialogTrancheNumber(undefined);
			this.$emit("update:value", false);
		},
		async handleUploaded(file) {
			this.documentFile = file ?? null;
		},
		async upload() {
			const { name, type } = this.documentFile;
			
			const res = await this.addTrancheDocument(new ApiFile(this.documentFile,
				name,
				STORAGE_TRANCHE_DOCUMENT_NAMESPACE,
				type,
				""));
			
			if(res)
				this.handleClose();
		}
	},
	created() {
		abortService.initialize();
	},
	components: {
		FrpDigitalSignatureDialog, BarDropzone,
		FrpDialog,
		FrpBtn
	}
};
</script>
