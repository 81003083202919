import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "bank-accounts-list";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	processRouteQuery: "processRouteQuery",
	processRouteParams: "processRouteParams",
	processRoute: "processRoute",
	pushRoute: "pushRoute",
	reconstituteRoute: "reconstituteRoute",
	fetchDictionaries: "fetchDictionaries",
	fetchSuggestedCounterparties: "fetchSuggestedCounterparties",
	setDefaultFilterValues: "setDefaultFilterValues"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_BANKS: "SET_BANKS",
	SET_SIGN_STATUSES: "SET_SIGN_STATUSES",
	SET_BANK_ACCOUNT_TYPES: "SET_BANK_ACCOUNT_TYPES",
	SET_CURRENCIES: "SET_CURRENCIES",
	SET_BANK_RESPONSIBLE_USERS: "SET_BANK_RESPONSIBLE_USERS",
	SET_MONITORING_SYSTEM_CONNECTION_STATUSES: "SET_MONITORING_SYSTEM_CONNECTION_STATUSES",
	SET_FILTER_LOAN_APPLICATION_NUMBER: "SET_FILTER_LOAN_APPLICATION_NUMBER",
	SET_FILTER_COUNTERPARTY_ID: "SET_FILTER_COUNTERPARTY_ID",
	SET_FILTER_BANK_ID: "SET_FILTER_BANK_ID",
	SET_FILTER_SIGN_STATUSES: "SET_FILTER_SIGN_STATUSES",
	SET_FILTER_RESPONSIBLE_USER_ID: "SET_FILTER_RESPONSIBLE_USER_ID",
	SET_FILTER_MONITORING_SYSTEM_CONNECTION_STATUSES: "SET_FILTER_MONITORING_SYSTEM_CONNECTION_STATUSES",
	SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING: "SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING",
	RESET_SUGGESTED_COUNTERPARTIES: "RESET_SUGGESTED_COUNTERPARTIES",
	SET_SUGGESTED_COUNTERPARTIES: "SET_SUGGESTED_COUNTERPARTIES"
};

const bankAccountsListTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountsListTypes;
