import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import { ApiHrEmployees } from "@/api/hr/types/apiHrEmployees";
import { ApiHrStandard } from "@/api/hr/types/apiHrStandard";
import { ApiHrVacation } from "@/api/hr/types/apiHrVacation";
import { ApiHrDepartmentsItem } from "@/api/hr/types/apiHrDepartmentsItem";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { mocks } from "@/api/client/mock";
import { ApiHrDiscipline } from "@/api/hr/types/apiHrDiscipline";
import { ApiHrPromise } from "@/api/hr/types/apiHrPromise";
import { ApiHrCreateVacationPlanRequest } from "@/api/hr/types/vacation/apiHrCreateVacationPlanRequest";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { ApiHrAdministrationEmployeesItem } from "@/api/hr/types/vacation/apiHrAdministrationEmployeesItem";
import { ApiHrUpdateEmployeeVacationDaysRequest } from "@/api/hr/types/vacation/apiHrUpdateEmployeeVacationDaysRequest";
import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import { ApiHrVacationPlan } from "@/api/hr/types/vacation/apiHrVacationPlan";
import { ApiHrImportEmployeesResponse } from "@/api/hr/types/vacation/apiHrImportEmployeesResponse";
import { ApiHrImportEmployeesRequest } from "@/api/hr/types/vacation/apiHrImportEmployeesRequest";
import { ApiHrGetAdministrationEmployeesParameters } from "@/api/hr/types/vacation/apiHrGetAdministrationEmployeesParameters";
import { ApiHrVacationPlansItem } from "@/api/hr/types/vacation/apiHrVacationPlansItem";
import { ApiHrAcceptPlanRequest } from "@/api/hr/types/vacation/apiHrAcceptPlanRequest";
import { ApiHrGetVacationPlansParameters } from "@/api/hr/types/vacation/apiHrGetVacationPlansParameters";
import { ApiGetFileResponse } from "@/api/types/storage/apiGetFileResponse";
import { ApiHrGetCurrentUserRequest } from "@/api/hr/types/apiHrGetCurrentUserRequest";
import { ApiHrGetTasksParameters } from "@/api/hr/types/tasks/apiHrGetTasksParameters";
import { ApiHrTasks } from "@/api/hr/types/tasks/apiHrTasks";
import { ApiHrDownloadArchiveParameters } from "@/api/hr/types/apiHrDownloadArchiveParameters";
import { ApiHrTaskStats } from "@/api/hr/types/tasks/apiHrTaskStats";
import { ApiHrVacationApplicationApproval } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationApproval";

export class HrController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getEmployees = async (parameters: ApiHrGetEmployeesParameters) => {
		return await this.client.get<ApiHrEmployees>(prepareUrl(urls.hr.employee.getEmployees, parameters));
	};
	
	getEmployeeProfile = async (id: string) => {
		return await this.client.get<ApiHrEmployee>(urls.hr.employee.getProfile.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeProfilePhotoFileId = async (employeeId: string) => {
		return await this.client.get<string>(urls.hr.employee.getEmployeeProfilePhotoFileId.replace(urlTemplateParts.id, employeeId));
	};
	
	getBitrixPromises = async (id: string) => {
		return await this.client.get<ApiHrPromise>(urls.hr.employee.getBitrixPromises.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeStandard = async (id: string) => {
		// return mocks.standardAwareness;
		return await this.client.get<ApiHrStandard>(urls.hr.employee.getStandard.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeDiscipline = async (id: string) => {
		return await this.client.get<ApiHrDiscipline>(urls.hr.employee.getDiscipline.replace(urlTemplateParts.id, id));
	};
	
	getEmployeePromise = async (id: string) => {
		// return await this.client.get<ApiHrPromise>(urls.hr.employee.getPromise.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeExpectation = async (id: string) => {
		// return await this.client.get<ApiHrExpectation>(urls.hr.employee.getExpectation.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeFeedback = async (id: string) => {
		return mocks.feedback;
		// return await this.client.get<ApiHrFeedback>(urls.hr.employee.getFeedback.replace(urlTemplateParts.id, id));
	};
	
	getEmployeeVacation = async (id: string) => {
		return await this.client.get<ApiHrVacation>(urls.hr.employee.getVacation.replace(urlTemplateParts.id, id));
	};
	
	getColleagues = async (id: string) => {
		return await this.client.get<ApiHrEmployee[]>(urls.hr.employee.getColleagues.replace(urlTemplateParts.id, id));
	};
	
	getSubordinates = async (id: string) => {
		return await this.client.get<ApiHrEmployee[]>(urls.hr.employee.getSubordinates.replace(urlTemplateParts.id, id));
	};
	
	getDepartments = async () => {
		return await this.client.get<ApiHrDepartmentsItem[]>(urls.hr.department.getDepartments);
	};
	
	getPermissions = async () => {
		return await this.client.get<string[]>(urls.hr.plannedVacation.getPermissions);
	};
	
	getEmployeePermissions = async (employeeId: string) => {
		return await this.client.get<string[]>(prepareUrl(urls.hr.employee.getPermissions, { employeeId }).replace(urlTemplateParts.id,
			employeeId));
	};
	
	getCurrentUser = async () => {
		return await this.client.get<ApiHrGetCurrentUserRequest>(urls.hr.user.getCurrentUser);
	};
	
	getLastYear = async () => {
		return await this.client.get<ApiHrVacationYear>(urls.hr.administration.getLastYear);
	};
	
	getYear = async (year: string) => {
		return await this.client.get<ApiHrVacationYear>(urls.hr.administration.getYear.replace(urlTemplateParts.id, year));
	};
	
	downloadArchive = async (parameters: ApiHrDownloadArchiveParameters) => {
		return this.client.get<ApiGetFileResponse>(prepareUrl(urls.hr.vacationApplication.downloadVacationArchive, parameters),
			{ responseType: "blob" },
			true);
	};
	
	getAdministrationYears = async () => {
		return await this.client.get<ApiHrVacationYear[]>(urls.hr.administration.getYears);
	};
	
	getVacationPlanYears = async () => {
		return await this.client.get<ApiHrVacationYear[]>(urls.hr.plannedVacation.getYears);
	};
	
	saveYear = async (year: string, holidays: string[]) => {
		return await this.client.put<ApiHrVacationYear>(urls.hr.administration.updateYear.replace(urlTemplateParts.id, year), { holidays });
	};
	
	importVacationPlanEmployees = async (year: string, parameters: ApiHrImportEmployeesRequest) => {
		return await this.client.post<ApiHrImportEmployeesResponse>(prepareUrl(urls.hr.administration.importYearEmployees
																				   .replace(urlTemplateParts.id, year), parameters), {});
	};
	
	getAdministrationEmployeesItems = async (year: string, parameters: ApiHrGetAdministrationEmployeesParameters) => {
		return await this.client.get<ApiHrAdministrationEmployeesItem[]>(prepareUrl(urls.hr.administration.getYearEmployees.replace(
			urlTemplateParts.id,
			year), parameters));
	};
	
	importVacationPlanEmployee = async (year: string, staffId: string) => {
		return await this.client.post<ApiHrImportEmployeesResponse>(urls.hr.administration.addYearEmployee
																		.replace(urlTemplateParts.id, year)
																		.replace(urlTemplateParts.subId, staffId), {});
	};
	
	deleteVacationPlanEmployee = async (year: string, staffId: string) => {
		return await this.client.delete(urls.hr.administration.deleteYearEmployee
											.replace(urlTemplateParts.id, year)
											.replace(urlTemplateParts.subId, staffId));
	};
	
	updateEmployeeVacationDays = async (year: string, staffId: string, payload: ApiHrUpdateEmployeeVacationDaysRequest) => {
		return await this.client.put<void>(urls.hr.administration.updateEmployeeVacationDays
											   .replace(urlTemplateParts.id, year)
											   .replace(urlTemplateParts.subId, staffId), payload);
	};
	
	updateEmployeeCanCreateUnplannedVacationApplication = async (year: string,
		staffId: string,
		canCreateUnplannedVacationApplication: boolean) => {
		return await this.client.put<void>(urls.hr.administration.updateEmployeeCanCreateUnplannedVacationApplication
											   .replace(urlTemplateParts.id, year)
											   .replace(urlTemplateParts.subId, staffId), { canCreateUnplannedVacationApplication });
	};
	
	getVacationPlanDepartments = async () => {
		return await this.client.get<ApiHrDepartment[]>(urls.hr.plannedVacation.getVacationPlanDepartments);
	};
	
	getVacationPlanEmployees = async () => {
		return await this.client.get<ApiHrEmployeesItem[]>(urls.hr.plannedVacation.getVacationPlanEmployees);
	};
	
	getLatestVacationPlans = async (year: string, parameters?: ApiHrGetVacationPlansParameters) => {
		const url = prepareUrl(urls.hr.plannedVacation.getLatestVacationPlans.replace(urlTemplateParts.id, year), parameters);
		return await this.client.get<ApiHrVacationPlansItem[]>(url);
	};
	
	getVacationPlans = async (year: string, parameters?: ApiHrGetVacationPlansParameters) => {
		const url = prepareUrl(urls.hr.plannedVacation.getVacationPlans.replace(urlTemplateParts.id, year), parameters);
		return await this.client.get<ApiHrVacationPlansItem[]>(url);
	};
	
	saveVacationPlan = async (year: string, staffId: string, payload: ApiHrCreateVacationPlanRequest) => {
		return await this.client.post<ApiHrVacationPlan>(urls.hr.plannedVacation.createVacationPlan
															 .replace(urlTemplateParts.id, year)
															 .replace(urlTemplateParts.subId, staffId), payload);
	};
	
	saveAndApproveVacationPlan = async (year: string, staffId: string, payload: ApiHrCreateVacationPlanRequest) => {
		return await this.client.post<ApiHrVacationPlan>(urls.hr.plannedVacation.createAndApproveVacationPlan
															 .replace(urlTemplateParts.id, year)
															 .replace(urlTemplateParts.subId, staffId), payload);
	};
	
	sendVacationPlanToReview = async (year: string, staffId: string, vacationPlanId: string) => {
		return await this.client.post<void>(urls.hr.plannedVacation.sendVacationPlanToReview
												.replace(urlTemplateParts.id, year)
												.replace(urlTemplateParts.subId, staffId)
												.replace(urlTemplateParts.thirdId, vacationPlanId), {});
	};
	
	approveVacationPlan = async (year: string, request: ApiHrAcceptPlanRequest) => {
		return await this.client.post<void>(urls.hr.plannedVacation.approveVacationPlan
												.replace(urlTemplateParts.id, year)
												.replace(urlTemplateParts.subId, request.employeeId)
												.replace(urlTemplateParts.thirdId, request.planId), {});
	};
	
	approveVacationPlans = async (year: string, vacationPlanIds: string[]) => {
		return await this.client.post<void>(urls.hr.plannedVacation.approveVacationPlans
												.replace(urlTemplateParts.id, year), { vacationPlanIds });
	};
	
	declineVacationPlan = async (year: string, request: ApiHrAcceptPlanRequest) => {
		return await this.client.post<void>(urls.hr.plannedVacation.rejectVacationPlan
												.replace(urlTemplateParts.id, year)
												.replace(urlTemplateParts.subId, request.employeeId)
												.replace(urlTemplateParts.thirdId, request.planId), { reason: request.reason });
	};
	
	withdrawVacationPlan = async (year: string, staffId: string, vacationPlanId: string) => {
		return await this.client.post<void>(urls.hr.plannedVacation.withdrawVacationPlan
												.replace(urlTemplateParts.id, year)
												.replace(urlTemplateParts.subId, staffId)
												.replace(urlTemplateParts.thirdId, vacationPlanId), {});
	};
	
	deleteDraft = async (year: string, staffId: string, vacationPlanId: string) => {
		return await this.client.delete(urls.hr.plannedVacation.deleteDraft
											.replace(urlTemplateParts.id, year)
											.replace(urlTemplateParts.subId, staffId)
											.replace(urlTemplateParts.thirdId, vacationPlanId));
	};
	
	exportPlans = async (year: string) => {
		return await this.client.get<ApiGetFileResponse>(urls.hr.plannedVacation.exportPlans.replace(urlTemplateParts.id, year),
			{ responseType: "blob" }, true);
	};
	
	getTasks = async (parameters: ApiHrGetTasksParameters) => {
		// return mocks.getTasks;
		return await this.client.get<ApiHrTasks>(prepareUrl(urls.hr.task.getTasks, parameters));
	};
	
	approveTask = async (taskId: string) => {
		return await this.client.post<void>(urls.hr.task.approveTask.replace(urlTemplateParts.id, taskId), {});
	};
	
	declineTask = async (parameters: { taskId: string }, request: { reason: string }) => {
		return await this.client.post<void>(prepareUrl(urls.hr.task.declineTask, parameters), { reason: request.reason });
	};
	
	signTask = async (parameters: { taskId: string }) => {
		return await this.client.post<void>(prepareUrl(urls.hr.task.signTask, parameters), {});
	};
	
	declineSigningTask = async (parameters: { taskId: string }, request: { reason: string }) => {
		return await this.client.post<void>(prepareUrl(urls.hr.task.declineSigningTask, parameters), { reason: request.reason });
	};
	
	markTaskInformationAsDone = async (parameters: { taskId: string }) => {
		return await this.client.post<void>(prepareUrl(urls.hr.task.markTaskInformationAsDone, parameters), {});
	};
	
	getTasksPromises = async (parameters: ApiHrGetTasksParameters) => {
		return await this.client.get<ApiHrTaskStats[]>(prepareUrl(urls.hr.task.getTaskStats, parameters));
	};
	
	getApproval = async (approvalId: string) => {
		return await this.client.get<ApiHrVacationApplicationApproval>(urls.hr.approval.getApproval.replace(urlTemplateParts.id,
			approvalId), {});
	};
	
	removeApprover = async (approvalId: string, approverId: string) => {
		return await this.client.delete<ApiHrVacationApplicationApproval>(urls.hr.approval.removeApprover
																		   .replace(urlTemplateParts.id, approvalId)
																		   .replace(urlTemplateParts.subId, approverId));
	};
	
	uploadProfilePhoto = async (employeeId: string, tempFileId: string) => {
		return await this.client.put<{ pictureFileId: string }>(urls.hr.employee.uploadOwnProfilePhoto
																		   .replace(urlTemplateParts.id, employeeId)
																		   .replace(urlTemplateParts.subId, tempFileId), {});
	};
}
